<template>
  <v-sheet class="transparent page-sheet">
    <v-row justify="end">
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" clearable></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="items"
      item-key="idx"
      sort-by="created"
      class="transparent table-custom"
      :footer-props="foot_props"
      :no-data-text="$t('label.nodata')"
    >
      <template #item.alias="props">
        <v-edit-dialog :return-value.sync="props.item.alias" @save="save(props.item)">
          {{ props.item.alias }}
          <template #input>
            <v-text-field v-model="props.item.alias" label="Edit" single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import commonmixin from "@/mixins/commonlist.js";
const apiBase = "v1/cms/workstations";

export default {
  name: "Workstations",
  mixins: [commonmixin],
  computed: {},
  data() {
    return {
      item: {},
      d_edit: false,
      headers: [
        {
          text: this.$t("fields.wsid"),
          value: "wsid",
        },
        {
          text: this.$t("fields.name"),
          value: "alias",
        },
        {
          text: this.$t("fields.valid"),
          value: "valid",
        },
        {
          text: this.$t("fields.mac"),
          value: "mac",
        },
        {
          text: this.$t("fields.license"),
          value: "license",
        },
        {
          text: this.$t("fields.created"),
          value: "created",
        },
      ],
    };
  },
  methods: {
    save(i) {
      this.$api.apiPostRequest(apiBase + "/alias", i);
    },
    loadData() {
      this.$api
        .apiGetRequest(apiBase)
        .then((response) => {
          this.items = [...response];
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style>
</style>